<!--
Format file upload  -> registrasi/simpanan_yyyy-MM-dd.xlsx
-->
<template>
    <div>
        <v-main>
            <v-container fluid>
                <v-alert
                color="red"
                dense
                dismissible
                type="error"
                :value="isError"
                >
                {{errMsg}}
                </v-alert>
                <v-row class="pa-5">
                    <div align="left">
                        <span>Format file upload:</span><br />
                        <span>* registrasi_[tgl transaksi - yyyy-MM-dd] <i>(cth: registrasi_2022-01-01.xlsx)</i></span><br />
                        <span>* simpanan_[tgl transaksi - yyyy-MM-dd] <i>(cth: simpanan_2022-01-01.xlsx)</i></span>
                    </div>
                </v-row>
                <v-row 
                    no-gutters
                    justify="center">
                    <v-col lg="3">
                        <section class="mb-2">
                            <v-btn 
                                color="primary" 
                                width="200" 
                                :loading="isLoad"
                                :disabled="isLoad"
                                @click="$refs.file.click()" 
                                >Upload File</v-btn>
                            <input type="file" @change="onChange" ref="file" style="display: none" accept=".xlsx" />
                            <xlsx-read :file="file">
                                <xlsx-json>
                                    <template #default="{collection}">
                                    <div>
                                        {{ onData(collection) }}
                                    </div>
                                    </template>
                                </xlsx-json>
                            </xlsx-read>
                        </section>
                    </v-col>
                </v-row>
                <template v-if="isValid">
                    <v-alert
                    dense
                    dismissible
                    type="warning"
                    >
                    Periksa kembali data sebelum melakukan proses selanjutnya
                    </v-alert>
                    <v-row class="pa-5">
                        <h3>Extract {{this.file != null ? this.file.name : ''}}</h3>
                    </v-row>
                    <v-row class="pa-5">
                        <h5>Jumlah data diextract: {{collections.length}}</h5>
                        <v-spacer />
                        <section>
                            <v-btn :disabled="!fileName.toLowerCase().startsWith('registrasi')" :loading="isLoadMember" @click="onBulk('registrasi')" class="mr-2" color="primary">
                                <v-icon small>mdi-upload</v-icon>
                                Bulk Registrasi
                            </v-btn>
                            <v-btn :disabled="!fileName.toLowerCase().startsWith('simpanan')" :loading="isLoadDeposite" @click="onBulk('simpanan')" class="success mr-2">
                                <v-icon small>mdi-upload</v-icon>
                                Bulk Simpanan
                            </v-btn>
                            <v-btn @click="clear()" class="error">
                                Batal
                            </v-btn>
                        </section>
                        <table class="mt-2">
                            <tr>
                                <th>Nama</th>
                                <th>Email</th>
                                <th>No AIMS</th>
                                <th>Badan</th>
                                <th>Cabang</th>
                                <th>S.Pokok</th>
                                <th>S.Wajib</th>
                                <th>Bank</th>
                                <th>Tgl.Transaksi</th>
                            </tr>
                            <template v-for="(data, i) in collections">
                                <tr :key="i">
                                    <td>{{data.fullName}}</td>
                                    <td>{{data.email}}</td>
                                    <td>{{data.noAims}}</td>
                                    <td>{{data.tanzeem}}</td>
                                    <td>{{data.branch}}</td>
                                    <td>{{data.nominalPokok}}</td>
                                    <td>{{data.nominalWajib}}</td>
                                    <td>{{data.bankName}}</td>
                                    <td>{{data.transactionDate}}</td>
                                </tr>
                            </template>
                        </table>
                    </v-row>
                </template>
            </v-container>
            <v-snackbar
              v-model="snackbar"
              timeout="4000"
            >
              Data sudah berhasil masuk
            </v-snackbar>
        </v-main>
    </div>
</template>
<style>
table {
  font-family: arial, sans-serif;
  font-size: 12px;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

</style>
<script>
import XlsxRead from "@/components/xlsx/XlsxRead";
import XlsxJson from "@/components/xlsx/XlsxJson";

export default {
    components: {
        XlsxRead,
        XlsxJson,
    },
    data() {
        return {
            snackbar: false,
            isLoad: false,
            isLoadMember: false,
            isLoadDeposite: false,
            isError: false,
            isValid: false,
            file: null,
            fileName: null,
            collections: [],
            transDate: null,
            errMsg:null
        }
    },
    methods: {
        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            this.validateFile()
        },
        onData(data) {
            if(data!=null && this.isValid) {
                this.init()
                for(let i = 0; i < data.length; i++) {
                    this.addRow(i, data)
                }
            }
            return
        },
        validateFile() {
            this.isValid = false;
            if(this.file != null) {
                let fileName = this.file.name.replace('.xlsx','')
                const names = fileName.split('_')
                if(names[1] != undefined && (fileName.toLowerCase().startsWith('registrasi') || fileName.toLowerCase().startsWith('simpanan'))) {
                    this.transDate = names[1]
                    this.isValid = true
                    this.fileName = fileName
                }
            }
            if(!this.isValid) {
                this.isError=true
                this.errMsg='Gagal memproses! Pastikan kembali format file yang akan diupload'
            }
        },
        init() {
            this.isLoad=true
            this.isError=false
        },
        clear() {
            this.isLoad=false
            this.isLoadMember= false,
            this.isLoadDeposite= false,
            this.file=null
            this.transDate=null
            this.isValid=false
            this.fileName=null
            this.collections= [],
            this.transDate= null,
            this.$refs.file.value=''
        },
        addRow(i, data) {
            this.collections[i] = {
                fullName: this.$root.setEmptyStringIfNull(data[i]['Nama']),
                email: this.$root.setEmptyStringIfNull(data[i]['Email']),
                noAims: this.$root.setEmptyStringIfNull(data[i]['No AIMS']),
                branch: this.$root.setEmptyStringIfNull(data[i]['Cabang']),
                tanzeem: this.$root.setEmptyStringIfNull(data[i]['Badan']),
                nominalPokok: this.$root.setNolIfNull(data[i]['Simpanan Pokok']),
                nominalWajib: this.$root.setNolIfNull(data[i]['Simpanan Wajib']),
                bankName: this.$root.setEmptyStringIfNull(data[i]['Bank']),
                transactionDate: this.$root.setEmptyStringIfNull(this.transDate)
            }
        },
        onBulk(type) {
            if(type==='registrasi') {
                this.isLoadMember=true
                this.sentAPI(this.$root.api.post.bulkMember)
            } else {
                this.isLoadDeposite=true
                this.sentAPI(this.$root.api.post.bulkDeposite)
            }
        },
        sentAPI(url) {
            this.$root.api.sentAPI({
                method: 'post',
                api: url,
                form: {
                    data: this.collections
                }
            }).then((result) => {
                if (result.responseStatus.responseCode != '00') {
                    this.errMsg = result.responseStatus.responseDesc
                    this.isError=true
                }else{
                    this.snackbar=true
                }
                console.log(JSON.stringify(result))
            }).catch((err) => {
                console.log(JSON.stringify(err))
            }).finally(()=>{
                this.clear()
            });
        }
    },
    mounted() {
        this.errMsg='Gagal memproses! Pastikan kembali format file yang akan diupload'
    }
}
</script>
